/* eslint-disable no-invalid-this */

import { commonVariable } from '../globalConstants/variableDeclaration.constant'

const bdsVideoComponent = (): void => {
    const $ = window.$
    const akamai = window.Akamai

    $(document).ready(function () {
        if ($(commonVariable.videoModal)) {
            if (
                $(commonVariable.breadcrumbClass).next().hasClass('video') &&
                window.innerWidth > commonVariable.mobileWidth
            ) {
                $('li.breadcrumb-item').css('color', 'white')
                $('li.breadcrumb-item .breadcrumb-element').css('color', 'white')
                $('li.breadcrumb-item').css('z-index', '10')
                $(commonVariable.breadCrumbRight).css('height', '1rem')
                $(commonVariable.breadCrumbRight).css('width', '1rem')
                $(commonVariable.breadCrumbRight).css('fill', 'white')
                $(commonVariable.breadcrumbClass).css('position', 'absolute')
                $(commonVariable.breadcrumbClass).css('display', 'block')
                $(commonVariable.breadcrumbClass).next().find('.videoContainer').css('padding-top', '0')
                $(commonVariable.breadcrumbClass).css('width', 'auto')
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (
                $(commonVariable.breadcrumbClass).next().hasClass('video') &&
                window.innerWidth <= commonVariable.mobileWidth
            ) {
                $('.landingBack .mobile-secondary-navigation-top__back--link').css('color', 'white')
                $('.landingBack .nl-icon-ct-chevron-left').css('fill', 'white')
                $(commonVariable.breadcrumbClass).css('position', 'absolute')
                $(commonVariable.breadcrumbClass).css('display', 'block')
                $(commonVariable.breadcrumbClass).next().find('.videoContainer').css('padding-top', '0')
                $(commonVariable.breadcrumbClass).css('width', 'auto')
            }

            $('.play-icon').click(function (this: any) {
                if ($(this).closest('.video').prev().hasClass('cmp-breadcrumb')) {
                    $(this).closest('.video').prev().hide()
                }
                $(this).closest(commonVariable.videoImage).hide()
                $(this).closest(commonVariable.videoImage).parent().children(commonVariable.videoModal).show()
                loadVideo()
            })
            $('.close-icon').click(function (this: any) {
                if ($(this).closest('.video').prev().hasClass('cmp-breadcrumb')) {
                    $(this).closest('.video').prev().show()
                }
                const videoElement = $(this).parent().parent().find('video')[0]
                videoElement?.pause()
                videoElement?.removeAttribute('src') // empty source
                videoElement?.load()
                $(this).closest(commonVariable.videoModal).hide()
                $(this).closest(commonVariable.videoModal).parent().children(commonVariable.videoImage).show()
            })
        }
        /**
         * this function will load video
         */
        function loadVideo() {
            const element = $('.video-modal-data [data-akamai-video]')[0]
            // eslint-disable-next-line sonar/constructor-for-side-effects
            new akamai.Viewer(element, {
                video: {
                    autoplay: false, // Automatically play the video on load
                    controls: true, // Display the video controls
                    loop: false, // Restart the video when it reaches the end
                    muted: true, // Mute the video
                },
            })
        }
    })
}

export { bdsVideoComponent }
