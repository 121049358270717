/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { commonVariable } from '../globalConstants/variableDeclaration.constant'

const bdsFeatureListComponent = (): void => {
    const $ = window.$

    $(document).ready(function () {
        if ($('.nl-sixCol-featuredlist')) {
            $('.nl-sixCol-featuredlist .regular').each(function (this: any, index: number) {
                const numItems = $($('.regular').eq(index).children('.nl-card--type2')).length
                $(this).find('.nl-card--type2__first-paragraph--bold').addClass('active')
                if (numItems > commonVariable.three) {
                    if ($('.nl-sixCol-featuredlist .regular')) {
                        $(this).slick({
                            dots: true,
                            infinite: false,
                            speed: 300,
                            slidesToShow: commonVariable.three,
                            slidesToScroll: commonVariable.three,
                        })
                    } else {
                        $(this).find('.nl-card--type2__second-paragraph').addClass('para')
                    }
                }
            })
        }
    })
}

export { bdsFeatureListComponent }
